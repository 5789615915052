<template>
  <vs-row class="mt-4" v-if="layout.stylesheet">
    <!-- LEFT SIDE -->
    <vs-col vs-w="6" v-if="layout.preconfigured_layout_id == null">
      <!-- layout.preconfigured_layout_id===null&&organization.id!=='' -->
      <!-- HEADER COLOR PICKER -->
      <!-- <vs-row>
        <vs-col vs-w="10">
          <span>Farbe Balken unten</span>
        </vs-col>
      
        <vs-col vs-w="2" vs-type="flex">
          <div id="option_text_color" class="color-box" 
              :style="`background-color:${layout.stylesheet.header_background_color}`" 
              @click="showBgColorPicker=!showBgColorPicker">
            <div v-show="showBgColorPicker" class="colorpicker-box">
              <color-panel v-model="layout.stylesheet.header_background_color" />
            </div>
          </div>
          <span class="cross" @click="showBgColorPicker=!showBgColorPicker" v-show="showBgColorPicker">
            <img src="/images/cross.png" alt="" style="width: 25px;" />
          </span>
        </vs-col>
      </vs-row> -->

      <!-- TEXT CENTER FIELD -->
      <vs-row>
        <vs-textarea rows="5" wrap="hard" label="Text-Mitte" class="date-input-md text-center" 
          v-model="layout.backside_text" />
      </vs-row>

      <!-- TEXT BOTTOM -->
      <vs-row>
        <vs-input class="inputx date-input-md" :label-placeholder="$t('Text unten')" v-model="layout.backside_footer_text" />
      </vs-row>

    </vs-col>

    <!-- RIGHT: IDCARD MOCKUP -->
    <vs-col vs-w="6">
      <vs-row>
        <div id="idcard" 
          :style="`
            background-color:${layout.stylesheet.body_background_color};
            height:275px; width:450px; 
            border:1px solid; border-radius:5px; border-color: #D1D1D1;

          `">
         <div v-if="layout.preconfigured_layout_id===1" class="body mt-3" style="height:230px;">
            <vs-row class="p-3" >
              <vs-col vs-w="4" style="max-width:100px;max-height:100px; margin-right:15px;">
                <img src="/images/remote-pilot-cert/LBA_Logo_neu.svg" style="width:100px;height:100px;" />
              </vs-col>
              <vs-col vs-w="4" class="box yellow">
                <span class="text"><strong>A1/A3</strong></span><br/>
                <span class="textSub">OPEN SUB CATEGORY</span>
              </vs-col>
              <vs-col vs-w="4" class="box red">
                <span class="text"><strong>A2</strong></span><br/>
                <span class="textSub">OPEN SUB CATEGORY</span>
              </vs-col>
              <vs-col vs-w="4" style="margin-top:12px; max-width:120px;max-height:75px;">
                <img src="/images/remote-pilot-cert/Deutschland-Logo.png" style="width:120px;height:75px;" />
              </vs-col>
            </vs-row>
            <vs-row :class="`ps-4 pe-4  text-center`" vs-justify="center" vs-w="12" :style="`font-size: 0.7rem; overflow:hidden; `">
              <vs-col>
                <div style="white-space: pre-wrap;">{{ layout.backside_text }}</div>
              </vs-col>
            </vs-row>
          </div>
          <div v-else-if="layout.preconfigured_layout_id===2" class="body mt-2" style="height:235px;">
            <vs-row class="pt-1 ps-3 pb-2" >
              <vs-col vs-w="4" style="max-width:100px;max-height:100px; margin-right:60px;text-align: left;">
                <span class="text2">Jugendflamme I</span>
                <span class="textSub2"><strong>XX.XX.XXXX</strong></span>
              </vs-col>
              <vs-col vs-w="4" style="max-width:100px;max-height:100px; margin-right:54px;text-align: center;">
                <span class="text2">Jugendflamme II</span>
                <span class="textSub2" style="margin-right:16px;"><strong>XX.XX.XXXX</strong></span>
              </vs-col>
              <vs-col vs-w="4" style="max-width:100px;max-height:100px; margin-right:2px;text-align: left;">
                <span class="text2">Jugendflamme III</span>
                <span class="textSub2"><strong>XX.XX.XXXX</strong></span>
              </vs-col>
              <vs-col vs-w="4" style="margin-top:12px; max-width:120px;max-height:75px;">
                <span class="text2">Leistungsspange</span>
                <span class="textSub2"><strong>XX.XX.XXXX</strong></span>
              </vs-col>
              <vs-col vs-w="4" style="max-width:100px;max-height:100px; margin-left:48px;text-align: left;margin-top:12px">
                <span class="text2">Bundeswettbewerb</span>
                <span class="textSub2"><strong>XXXXXXXXXXXX</strong></span>
              </vs-col>
            </vs-row>
            <vs-row :class="`ps-4 pt-2 pe-4 text-center`" vs-justify="center" vs-w="12" :style="`font-size: 0.7rem; background-color:white; overflow:hidden; width: 100%; height: 15Vh;`">
              <vs-col>
                <div style="white-space: pre-wrap;">{{ layout.backside_text }}</div>
              </vs-col>
            </vs-row>
          </div>
          <div v-else class="body mt-3" style="height:230px;">
            <vs-row class="ps-3">
              <vs-col vs-w="12" class="ps-3">
                <vs-row  v-if="items[0]">
                  <vs-col vs-w="6" v-if="items[0]">
                    <small >{{ items[0] }}</small><br>
                    <strong>XXXXXXXXXXXXXXXXXXX</strong>
                  </vs-col>
                  <vs-col vs-w="6" v-if="items[1]">
                    <small>{{ items[1] }}</small><br>
                    <strong>XXXXXXXXXXXXXXXXXXX</strong>
                  </vs-col>
                </vs-row>
                <vs-row class="mt-3" v-if="items[2]">
                  <vs-col vs-w="6">
                    <small>{{ items[2] }}</small><br>
                    <strong>XXXXXXXXXXXXXXXXXXX</strong>
                  </vs-col>
                  <vs-col vs-w="6" v-if="items[3]">
                    <small>{{ items[3] }}</small><br>
                    <strong>XXXXXXXXXXXXXXXXXXX</strong>
                  </vs-col>
                </vs-row>
                <vs-row class="mt-3" v-if="items[4]">
                  <vs-col vs-w="6">
                    <small>{{ items[4] }}</small><br>
                    <strong>XXXXXXXXXXXXXXXXXXX</strong>
                  </vs-col>
                  <vs-col vs-w="6"  v-if="items[5]">
                    <small>{{ items[5] }}</small><br>
                    <strong>XXXXXXXXXXXXXXXXXXX</strong>
                  </vs-col>
                </vs-row>
                <vs-row class="mt-3" v-if="items[6]">
                  <vs-col vs-w="6">
                    <small>{{ items[6] }}</small><br>
                    <strong>XXXXXXXXXXXXXXXXXXX</strong>
                  </vs-col>
                  <vs-col vs-w="6"  v-if="items[7]">
                    <small>{{ items[7] }}</small><br>
                    <strong>XXXXXXXXXXXXXXXXXXX</strong>
                  </vs-col>
                </vs-row>
              </vs-col>
            </vs-row>
            <vs-row :class="`ps-4 pt-2 pe-4 mt-${this.rMargin} text-center`" vs-justify="center" vs-w="12" :style="`font-size: 0.7rem; background-color:white; overflow:hidden; width: 100%; height: ${this.rHeight};`">
              <vs-col>
                <div style="white-space: pre-wrap;">{{ layout.backside_text }}</div>
              </vs-col>
            </vs-row>

          </div>

          <div class="footer"
            :style="`
              background-color:${layout.stylesheet.header_background_color};
              height: 30px;
              text-align: center;
              border-radius: 0px 0px 5px 5px;
              padding: 5px;

            `">

            <span :style="`
              color: ${layout.stylesheet.header_text_color};
              font-weight: bold;
              margin-left: 19px;
              white-space: pre-wrap;
            `">
              {{layout.backside_footer_text}}
            </span>
          </div>

        </div>
      </vs-row>
    </vs-col>
  </vs-row>


  <!-- ID-CARD Mockup -->
  <!-- <div class="child">
    <div class="organization.frontSide-preview">
      <div class="body" :style="`background-color:${layout.stylesheet.body_background_color};height:200px;`">
        <p class="p-4">
          {{ layout.backside_text }}
        </p>
      </div>

      <div class="head" :style="`background-color:${layout.stylesheet.header_background_color};color:${layout.stylesheet.header_text_color};min-height:2.5rem;`">
        <h4>{{layout.backside_footer_text}}</h4>
      </div>
    </div>
  </div> -->


</template>

<script>
  import Vue from "vue";
  export default Vue.extend({
    props: ["layout"],
    data() {
      return {
        showBgColorPicker: false,
        issuing_authority: "",
        rHeight:"9vh",
        rMargin:"3",
        items: []
      };
    },
    watch: {
      layout:{
        handler(val){
          this.fillItems();
        },
        deep: true
      }
    },
    methods: {
      initFields() {
        this.items = [];
        if (this.layout.stylesheet.fields_back.find(f => f.key == 'department')) {
          this.items.push("Abteilung");
        }
        if (this.layout.stylesheet.fields_back.find(f => f.key == 'driver_license_class')) {
          this.items.push("Führerschein");
        }
        if (this.layout.stylesheet.fields_back.find(f => f.key == 'extra_1')) {
          this.items.push(this.layout.extra_1_caption);
        }
        if (this.layout.stylesheet.fields_back.find(f => f.key == 'extra_2')) {
          this.items.push(this.layout.extra_2_caption);
        }
        if (this.layout.stylesheet.fields_back.find(f => f.key == 'extra_3')) {
          this.items.push(this.layout.extra_3_caption);
        }
        if (this.layout.stylesheet.fields_back.find(f => f.key == 'extra_4')) {
          this.items.push(this.layout.extra_4_caption);
        }
        if (this.layout.stylesheet.fields_back.find(f => f.key == 'extra_5')) {
          this.items.push(this.layout.extra_5_caption);
        }
        if (this.layout.stylesheet.fields_back.find(f => f.key == 'extra_6')) {
          this.items.push(this.layout.extra_6_caption);
        }
      },
      async fillItems() {
        this.items = [];
        this.initFields();
        if (this.items.length > 4) {
          this.rHeight= "9vh";
          this.rMargin= "3";
        }else if (this.items.length > 2) {
          this.rHeight= "19vh";
          this.rMargin= "3";
        }else if (this.items.length > 0) {
          this.rHeight= "29.1vh";
          this.rMargin= "3";
        }else {
          this.rHeight= "39.3vh";
          this.rMargin= "0";
        }
      }
    }
  });
</script>
<style scoped>
  /* .content-section {
    font-family: "Open Sans", sans-serif;
    color: #2c3e50;
    height: 55vh;
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .content-section .child {
    flex: 1 1 40%;
    margin: 5px 10px;
  }
  .content-section .child .row-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 5px;
  } */


  .box {
    margin:12px;
    max-width: 75px;
    max-height:75px;
    padding-left: 5px;
    padding-top: 2px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .red {
    background-color: #9e0558;
  }
  .yellow {
    background-color: #fe6960;
  }
  .text {
    color:white;
    font-size: 21px;
    font-family: "Open Sans", sans-serif;
    vertical-align: middle;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .textSub {
    color:white;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    vertical-align: top;
    line-height: 1.1em;
  }
  .text2 {
    color:rgb(0, 0, 0);
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
    vertical-align: middle;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
		  
		  .textSub2 {
    color:black;
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    vertical-align: top;
    line-height: 1.1em;
  }

  .colorpicker-box {
    position: absolute;
    z-index: 10000;
    transform: translate(36px, -14px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .cross {
    z-index: -1;
    float: right;
    font-size: 23px;
    transform: translate(251px, -50px);
    cursor: pointer;
  }
  .color-box {
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border: 2px solid #e7e7e7;
    border-radius: 7px;
  }
  /* .organization.frontSide-preview {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
    height: 230px;
    margin: 20px;
    border: 1px solid #cccccc;
  }
  .organization.frontSide-preview .head {
    height: 50px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    padding: 7px;
    font-family: "Open Sans", sans-serif;
    color: #2c3e50;
  }
  .organization.frontSide-preview .head h4 {
    font-size: 20px;
  }
  .organization.frontSide-preview .body {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-top: 20px;
  }
  .organization.frontSide-preview .body .image {
    border: 1px solid #cccccc;
    padding: 5px;
    display: flex;
    margin: 5px 10px;
  }
  .organization.frontSide-preview .body .image img {
    width: 100px;
  } */
</style>
