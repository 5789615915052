<template>
  <vs-row class="mt-4" v-if="layout.stylesheet">
      
    <!-- LEFT: CONFIGURATION -->
    <vs-col vs-w="6" v-if="layout.preconfigured_layout_id == null">
    
      <!-- HEADER COLOR PICKER -->
      <vs-row>
        <vs-col vs-w="10">
          <span>Farbe Balken oben</span>
        </vs-col>
      
        <vs-col vs-w="2" vs-type="flex" vs-offset="-1.3">
          <div id="option_text_color" class="color-box" 
              :style="`background-color:${layout.stylesheet.header_background_color};`"
              @click="toggleColorPicker('showHeaderColorPicker')">
            <div v-show="showHeaderColorPicker" class="colorpicker-box">
              <color-panel v-model="layout.stylesheet.header_background_color" />
            </div>
          </div>
          <span class="cross" @click="showHeaderColorPicker=!showHeaderColorPicker" v-show="showHeaderColorPicker">
            <img src="/images/cross.png" alt="" style="width: 25px;" />
          </span>
        </vs-col>
      </vs-row>


      <!-- HEADER TEXT COLOR PICKER -->
      <vs-row class="mt-2">
        <vs-col vs-w="10">
          <span>Farbe Text oben</span>
        </vs-col>
      
        <vs-col vs-w="2" vs-type="flex" vs-offset="-1.3">
          <div id="option_text_color" class="color-box" 
              :style="`background-color:${layout.stylesheet.header_text_color}`" 
              @click="toggleColorPicker('showHeaderTextColorPicker')">
            <div v-show="showHeaderTextColorPicker" class="colorpicker-box">
              <color-panel v-model="layout.stylesheet.header_text_color" />
            </div>
          </div>
          <span class="cross" @click="showHeaderTextColorPicker=!showHeaderTextColorPicker" v-show="showHeaderTextColorPicker">
            <img src="/images/cross.png" alt="" style="width: 25px;" />
          </span>
        </vs-col>
      </vs-row>

      <!-- BODY BACKGROUND COLOR PICKER -->
      <vs-row class="mt-2">
        <vs-col vs-w="10" vs-type="flex">
          <span>Farbe Hintergrund</span>
        </vs-col>
        <vs-col vs-w="2"  vs-offset="-1.3">
          <div id="option_body_color" class="color-box" 
            :style="`background-color:${layout.stylesheet.body_background_color}`" 
            @click="toggleColorPicker('showBodyBackgroundColorPicker')">
            <div v-show="showBodyBackgroundColorPicker" class="colorpicker-box">
              <color-panel v-model="layout.stylesheet.body_background_color"></color-panel>
            </div>
          </div>
          <span class="cross" @click="showBodyBackgroundColorPicker=!showBodyBackgroundColorPicker" v-show="showBodyBackgroundColorPicker">
            <img src="/images/cross.png" alt="" style="width: 25px; margin-top: -65px;" />
          </span>

        </vs-col>
      </vs-row>
      <!-- HEADER TEXT -->
      <vs-row class="mt-4">
        <vs-input class="inputx date-input-md" :label-placeholder="$t('Text oben')" v-model="layout.title"
        :readonly="layout.based_on_template_id === '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'"
        :class="{
          'readonly': layout.based_on_template_id === '1bb04ad8-cfe0-46b1-9559-cf5a205482a3',
         }"
         :disabled=" layout.based_on_template_id === '1bb04ad8-cfe0-46b1-9559-cf5a205482a3'"
        @focus.prevent="layout.based_on_template_id === '1bb04ad8-cfe0-46b1-9559-cf5a205482a3' ? $event.target.blur() : null"/>
      </vs-row>


      <!-- LOGO TOP RIGHT -->
      <!-- <vs-row>
        <vs-col vs-w="10" vs-type="flex"  v-if="!layout.header_picture_id">
          <vs-input type="file" class="inputx date-input-md" :label="$t('Logo oben links')" @change="uploadImageTopRight" />
        </vs-col>
        <vs-col vs-w="10" vs-type="flex"  v-else>
          <vs-row>
            <vs-col vs-w="12">Logo oben links</vs-col>
            <vs-col vs-w="12">
              <vs-button style="margin-left:0px;" class="pop-btn w-100"  color="primary" type="border" icon="close" 
                @click="layout.header_picture_id=null">{{$t("delete")}}</vs-button>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-w="2">
          <img class="image-preview img-fluid mt-4" v-if="layout.header_picture_id" :src="'data:image/png;base64, ' + headerPictureImgSrc">   
        </vs-col>
      </vs-row> -->
      <!-- BACKGROUND IMAGE -->
      <!-- <vs-row>
        <vs-col v-if="!layout.background_picture_id" vs-w="10" vs-type="flex">
          <vs-input type="file" class="inputx date-input-md" :label="$t('Grafik Hintergrund')" @change="uploadImageBackground" />
        </vs-col>
        <vs-col v-else vs-w="10" vs-type="flex">
          <vs-row>
            <vs-col vs-w="12">Grafik Hintergrund</vs-col>
            <vs-col vs-w="12">
              <vs-button class="pop-btn w-100" style="margin-left:0px;" color="primary" type="border"
                icon="close" @click="layout.background_picture_id=null">{{$t("delete")}}</vs-button>
          </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-w="2">
          <img class="image-preview img-fluid mt-4" v-if="layout.background_picture_id" :src="'data:image/png;base64, ' + backgroundImgSrc">   
        </vs-col>
      </vs-row>-->
    </vs-col> 


    <!-- RIGHT: IDCARD MOCKUP -->
    <vs-col vs-w="6">
      <vs-row>
        <div id="idcard" 
          :style="`
            background-color:${layout.stylesheet.body_background_color};
            height:275px; width:450px; 
            border:1px solid; border-radius:5px; border-color: #D1D1D1;
          `">
          <div v-if="layout.preconfigured_layout_id!==2" class="header"
            :style="`
              background-color:${layout.stylesheet.header_background_color};
              background-image: url(${layout.preconfigured_layout_id===1?'/images/remote-pilot-cert/EASA_Logo.svg':'data:image/png;base64,'+this.headerPictureImgSrc}),url(${layout.preconfigured_layout_id===1?'images/remote-pilot-cert/LBA_Logo_neu.svg':'data:image/png;base64,'+this.orgImgSrc});
              background-repeat: no-repeat, no-repeat;
              background-position: ${layout.preconfigured_layout_id===1?'left 15px top 15px,right 15px top 5px':'left 15px top 8px,right 15px top 8px'};
              background-size: ${layout.preconfigured_layout_id===1?'75px, 50px':'35px, 35px'};
              height: 50px;
              text-align: center;
              border-radius: 5px 5px 0px 0px;
              padding: 13px;  
            `">

            <span :style="`
              color: ${layout.stylesheet.header_text_color};
              font-size: 18px;
              font-weight: ${layout.preconfigured_layout_id===1?'bold':'normal'};
              white-space: pre;
            `">
              {{layout.title}}
            </span>
          </div>
          <div v-else class="header" 
            :style="`
              background-color:${layout.stylesheet.header_background_color};
              background-image: url(/images/Jugendfeuerwehr/DJF-LOGO-Flamme-BOSID.svg);
              background-repeat: no-repeat, no-repeat;
              background-position:left 30px top 2px,right 15px top 3px;
              background-size: 30px, 30px;
              height: 50px;
              text-align: left;
              border-radius: 5px 5px 0px 0px;
              padding: 3px;  
            `">

            <span :style="`
              color: ${layout.stylesheet.header_text_color};
              font-size: 18px;
              font-weight: normal;
              white-space: pre;
              line-height: 110%;
              padding-left: 70px;
              letter-spacing:1px;
            `">
              DEUTSCHE<br><b style="font-weight: 800; font-size: 19px; padding-left: 76px;">JUGEND</b>FEUERWEHR

            </span>
          </div>

          <div class="body mt-3">
            <vs-row class="p-3" >
              <vs-col vs-w="4" style="border:1px solid; width: 100px; padding-top:15px; height:140px;">
                <!-- width: 11vm; padding-top:1vm; height:14vm;"> -->
                <img src="/icons/user.svg" />
              </vs-col>
              <vs-col vs-w="8" class="ps-3">
                <div id="bg" :style="`
                background-image:linear-gradient(${this.rgbc}, ${this.rgbc}), url(${layout.preconfigured_layout_id===1?'/images/remote-pilot-cert/Drohnensymbol.JPG':layout.preconfigured_layout_id === 2 ? '/images/Jugendfeuerwehr/Flamme_Background.jpg':'data:image/png;base64,'+this.backgroundImgSrc});
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                `">
                <!-- linear-gradient(rgba(250,250,250, 0.8), rgba(250,250,250, 0.8)), -->
                  <vs-row v-if="layout.preconfigured_layout_id===1" class="h6">A1/A3 und A2 (open sub category)</vs-row>
                  <vs-row v-else-if="layout.preconfigured_layout_id===2" class="h6">JF Musterstadt</vs-row>
                  <vs-row v-else class="h5"><strong>Organisation Musterstadt</strong></vs-row>
                  <vs-row class="h5"><strong>Mario Mustermann</strong></vs-row>

                  <vs-row>
                    <vs-col vs-w="6">
                      <small>Geburtstdatum</small><br>
                      <strong>XX.XX.XXXX</strong>
                    </vs-col>
                    <vs-col vs-w="6">
                      <small>Funktion</small><br>
                      <strong v-if="layout.preconfigured_layout_id===1">Fernpilot</strong>
                      <strong v-else>XXXXXXXXXXXX</strong>
                    </vs-col>
                  </vs-row>
                  <vs-row>
                    <vs-col vs-w="6">
                      <small>Gültig bis</small><br>
                      <strong>XX.XX.XXXX</strong>
                    </vs-col>
                    <vs-col vs-w="6">
                      <small>Aussteller</small><br>
                      <strong v-if="layout.preconfigured_layout_id===1">Luftfahrtbundesamt</strong>
                      <strong v-else>XXXXXXXXXXXX</strong>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vs-row>
    </vs-col>
    
    
  </vs-row>
</template>

<script>
  import Vue from "vue";
  import { ColorPicker, ColorPanel } from "one-colorpicker";
  Vue.use(ColorPanel);
  Vue.use(ColorPicker);

  export default Vue.extend({
    props: ["layout","organization"],


    data() {
      return {
        showHeaderColorPicker: false,
        showHeaderTextColorPicker: false,
        showBodyBackgroundColorPicker: false,
        orgImgSrc:null,
        backgroundImgSrc: null,
        headerPictureImgSrc: null,
        newColor: '',
        rgbc:'',
        validColor: /^([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/        
      };
    },
    computed: {
    },
    watch: {
      organization: {
            deep: true,
            async handler(val) {
                if (this.organization.logo_image_id) {
                    this.orgImgSrc= await this.$store.dispatch('core/getImage64', this.organization.logo_image_id)
                } else {
                    this.orgImgSrc= null
                }
            },
      },
      layout: {
        deep: true,
        async handler() {
          this.backgroundImgSrc = await this.$store.dispatch('core/getImage64', this.layout.background_picture_id)
          this.headerPictureImgSrc = await this.$store.dispatch('core/getImage64', this.layout.header_picture_id)
          this.newColor = this.layout.stylesheet.body_background_color;
          this.newColor = this.newColor.replace("#","");
          if (this.newColor.indexOf("rgba") !== -1) {
            this.rgbc = this.newColor;
          } else {
            const hexValue = this.newColor.split('');
            let r, g, b;
            // 3 digit hex code (repeat same letter to make it as 6 digits)
            if (this.newColor.length === 3) {
              r = parseInt(hexValue[0].toString() + hexValue[0].toString(), 16);
              g = parseInt(hexValue[1].toString() + hexValue[1].toString(), 16);
              b = parseInt(hexValue[2].toString() + hexValue[2].toString(), 16);
              // 6 digt hex code
            } else if (this.newColor.length === 6) {
              r = parseInt(hexValue[0].toString() + hexValue[1].toString(), 16);
              g = parseInt(hexValue[2].toString() + hexValue[3].toString(), 16);
              b = parseInt(hexValue[4].toString() + hexValue[5].toString(), 16);
            }
            // If color code is not valid, show '---'
            if (this.validColor.test(this.newColor) !== true) {
              r = g = b = '---';
            }
            this.rgbc ='rgba('+r + ', ' + g + ', ' + b + ', 0.9)';
          }
        }
      }
    },
    
    methods: {
      toggleColorPicker(picker){
        this.showHeaderColorPicker = false
        this.showHeaderTextColorPicker = false
        this.showBodyBackgroundColorPicker = false

        if (picker === 'showHeaderColorPicker') {
          this.showHeaderColorPicker = true
        }
        if (picker === 'showHeaderTextColorPicker') {
          this.showHeaderTextColorPicker = true
        }
        if (picker === 'showBodyBackgroundColorPicker') {
          this.showBodyBackgroundColorPicker = true
        }
      },
    },
  });
</script>


<style scoped>

  .image-preview {
    height: 40px;
    width: 40px;
  }

  .content-section {
    font-family: "Open Sans", sans-serif;
    color: #2c3e50;
    height: 55vh;
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .content-section .child {
    flex: 1 1 40%;
    margin: 5px 10px;
  }
  .content-section .child .row-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 5px;
  }
  .colorpicker-box {
    position: absolute;
    z-index: 10000;
    transform: translate(36px, -14px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .cross {
    position: absolute;
    margin-left: 25px;
    font-size: 23px;
    transform: translate(251px, -50px);
    cursor: pointer;
    z-index: 999999;
  }
  .color-box {
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border: 2px solid #e7e7e7;
    border-radius: 7px;
  }
  .organization.frontSide-preview {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
    height: 130px;
    margin: 20px;
    border: 1px solid #cccccc;
  }
  .organization.frontSide-preview .head {
    height: 50px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    padding: 7px;
    font-family: "Open Sans", sans-serif;
    color: #2c3e50;
  }
  .organization.frontSide-preview .head h4 {
    font-size: 20px;
  }
  .organization.frontSide-preview .body {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-top: 20px;
  }
  .organization.frontSide-preview .body .image {
    border: 1px solid #cccccc;
    padding: 5px;
    display: flex;
    margin: 5px 10px;
  }
  .organization.frontSide-preview .body .image img {
    width: 100px;
  }
</style>
