<template>
  <div class="w-100" v-if="layout.stylesheet">
    <vs-row vs-w="12" >

      <!-- FRONTSIDE -->
      <vs-col vs-w="6" class="p-3">
        <vs-row vs-justify="space-between" class="mb-2">
         <h5>{{ $t("id-card-frontside") }}</h5>
        </vs-row>

        <vs-row vs-justify="space-between" class="pt-2 pb-2">
          <span>{{ $t("card-id") }}</span>
          <vs-switch :style="{right: '50%'}" v-model="fields_front.id_number" disabled="true" />
        </vs-row>
        <vs-row vs-justify="space-between" class="pt-2 pb-2">
          <span>{{ $t("organization") }}</span>
          <vs-switch :style="{right: '50%'}" v-model="fields_front.organization" disabled="true" />
        </vs-row>
        <vs-row vs-justify="space-between" class="pt-2 pb-2">
          <span>Name</span>
          <vs-switch :style="{right: '50%'}" v-model="fields_front.name" disabled="true" />
        </vs-row>
        <vs-row vs-justify="space-between" class="pt-2 pb-2">
          <span>{{ $t("date-birth") }}</span>
          <vs-switch :style="{right: '50%'}" v-model="fields_front.date_of_birth" disabled="true" />
        </vs-row>
        <vs-row vs-justify="space-between" class="pt-2 pb-2">
          <span>{{ $t("valid-until") }}</span>
          <vs-switch :style="{right: '50%'}" v-model="fields_front.valid_until" disabled="true" />
        </vs-row>
        <vs-row vs-justify="space-between" class="pt-2 pb-2">
          <span>{{ $t("function") }}</span>
          <vs-switch :style="{right: '50%'}" v-model="fields_front.function" disabled="true" />
        </vs-row>
        <vs-row vs-justify="space-between" class="pt-2 pb-2">
          <span>{{ $t("issuing-authority") }}</span>
          <vs-switch :style="{right: '50%'}" v-model="fields_front.issuing_authority_name" disabled="true" />
        </vs-row>
      </vs-col>


      <!-- BACKSIDE -->

      <vs-col vs-w="6" class="p-3">
        <vs-row vs-justify="space-between" class="mb-2">
          <h5>{{ $t("id-card-backside") }}</h5>
          <div class= "pb-2" ><span style="padding: 2px;color: #7eabcb;">Es können max. nur 6 Felder ausgewählt werden!</span></div>
        </vs-row>

        <vs-row :class="{ 'grayed-out': selectedFieldsCount >= 6 && !fields_back.department}" vs-justify="space-between" class="pt-2 pb-2">
          <span>{{ $t("department") }}</span>
          <vs-switch :style="{right: '50%'}" :disabled="isFieldDisabled('department')" v-model="fields_back.department"  
            @change="updateFields('back', 'department')" />
        </vs-row>
        <vs-row :class="{ 'grayed-out': selectedFieldsCount >= 6 && !fields_back.driver_license_class}" vs-justify="space-between" class="pt-2 pb-2">
          <span>{{ $t("driver-license") }}</span>
          <vs-switch :style="{right: '50%'}" :disabled="isFieldDisabled('driver_license_class')" v-model="fields_back.driver_license_class" 
            @change="updateFields('back', 'driver_license_class')" />
        </vs-row>
        <vs-row class="mt-3">
          <span>&#8203;</span>
        </vs-row>
        <vs-row :class="{ 'grayed-out': selectedFieldsCount >= 6 && !fields_back.extra_1 }" vs-justify="space-between" class="pt-2 pb-2">
          <span>Zusatz-1</span>
          <vs-switch :style="{right: '50%'}" :disabled="isFieldDisabled('extra_1')"
            v-model="fields_back.extra_1"
            @change="updateFields('back', 'extra_1')" />
        </vs-row>
        <vs-row :class="{ 'grayed-out': selectedFieldsCount >= 6 && !fields_back.extra_2 }" vs-justify="space-between" class="pt-2 pb-2">
          <span>Zusatz-2</span>
          <vs-switch :style="{right: '50%'}" :disabled="isFieldDisabled('extra_2')" v-model="fields_back.extra_2"
            @change="updateFields('back', 'extra_2')" />
        </vs-row>
        <vs-row :class="{ 'grayed-out': selectedFieldsCount >= 6 && !fields_back.extra_3 }" vs-justify="space-between" class="pt-2 pb-2">
          <span>Zusatz-3</span>
          <vs-switch :style="{right: '50%'}" :disabled="isFieldDisabled('extra_3')" v-model="fields_back.extra_3"
            @change="updateFields('back', 'extra_3')" />
        </vs-row>
        <vs-row :class="{ 'grayed-out': selectedFieldsCount >= 6 && !fields_back.extra_4 }" vs-justify="space-between" class="pt-2 pb-2">
          <span>Zusatz-4</span>
          <vs-switch :style="{right: '50%'}" :disabled="isFieldDisabled('extra_4')" v-model="fields_back.extra_4"
            @change="updateFields('back', 'extra_4')" />
        </vs-row>
        <vs-row :class="{ 'grayed-out': selectedFieldsCount >= 6 && !fields_back.extra_5 }" vs-justify="space-between" class="pt-2 pb-2">
          <span>Zusatz-5</span>
          <vs-switch :style="{right: '50%'}" :disabled="isFieldDisabled('extra_5')" v-model="fields_back.extra_5"
            @change="updateFields('back', 'extra_5')" />
        </vs-row>
        <vs-row :class="{ 'grayed-out': selectedFieldsCount >= 6 && !fields_back.extra_6 }" vs-justify="space-between" class="pt-2 pb-2">
          <span>Zusatz-6</span>
          <vs-switch :style="{right: '50%'}" :disabled="isFieldDisabled('extra_6')" v-model="fields_back.extra_6"
            @change="updateFields('back', 'extra_6')" />
        </vs-row>
      </vs-col>
    </vs-row>



    <!--  EXTRA FIELD 1 -->
    <vs-row vs-w="12" class="mt-4" v-if="hasExtraField(1)">
    <vs-divider />
      <vs-col vs-w="6">
        <vs-input v-model="layout.extra_1_caption" class="w-75" :label-placeholder="$t('Bezeichnung für Zusatz 1')" :disabled="isFieldDisabled('extra_1')" @change="updateFields('back', 'extra_1_caption')"/>
        <vs-row  class="mt-2">
          <span>{{ $t("Mehrfachauswahl") }}</span>
          <vs-switch class="ms-2" v-model="layout.extra_1_single_value" />
        </vs-row>
      </vs-col>
      
      <vs-col vs-w="6" v-if="layout.extra_1_single_value">
        <vs-row>
          <vs-input v-model="extra_1_multi_value_add" class="w-80" :label-placeholder="$t('Auswahl hinzufügen')"/>
          <div  class="pt-3 ms-3">
            <vs-button color="primary" type="filled" icon="add" @click="addExtraFieldOption(1)"></vs-button>
          </div>
        </vs-row>
        <vs-col vs-w="12" class="mt-2">
          <vs-chip @click="removeExtraFieldOption(1, v)" v-for="v, idx in layout.extra_1_values" :key="idx" closable>
            {{ v }}
          </vs-chip>
        </vs-col> 
       </vs-col> 
    </vs-row>



    <!--  EXTRA FIELD 2 -->
    <vs-row vs-w="12" class="mt-4" v-if="hasExtraField(2)">
    <vs-divider />
      <vs-col vs-w="6" >
        <vs-input v-model="layout.extra_2_caption" class="w-75" :label-placeholder="$t('Bezeichnung für Zusatz 2')" :disabled="isFieldDisabled('extra_2')" @change="updateFields('back', 'extra_2_caption')"/>
        <vs-row  class="mt-2">
          <span>{{ $t("Mehrfachauswahl") }}</span>
          <vs-switch class="ms-2" v-model="layout.extra_2_single_value" />
        </vs-row>
      </vs-col>
      
      <vs-col vs-w="6" v-if="layout.extra_2_single_value">
        <vs-row>
          <vs-input v-model="extra_2_multi_value_add" class="w-80" :label-placeholder="$t('Auswahl hinzufügen')"/>
          <div  class="pt-3 ms-3">
            <vs-button color="primary" type="filled" icon="add" @click="addExtraFieldOption(2)"></vs-button>
          </div>
        </vs-row>
        <vs-col vs-w="12" class="mt-2">
          <vs-chip @click="removeExtraFieldOption(2, v)" v-for="v, idx in layout.extra_2_values" :key="idx" closable>
            {{ v }}
          </vs-chip>
        </vs-col> 
       </vs-col> 
    </vs-row>



    <!--  EXTRA FIELD 3 -->
    <vs-row vs-w="12" class="mt-4" v-if="hasExtraField(3)">
    <vs-divider />
      <vs-col vs-w="6" >
        <vs-input v-model="layout.extra_3_caption" class="w-75" :label-placeholder="$t('Bezeichnung für Zusatz 3')" :disabled="isFieldDisabled('extra_3')" @change="updateFields('back', 'extra_3_caption')"/>
        <vs-row  class="mt-2">
          <span>{{ $t("Mehrfachauswahl") }}</span>
          <vs-switch class="ms-2" v-model="layout.extra_3_single_value" />
        </vs-row>
      </vs-col>
      
      <vs-col vs-w="6" v-if="layout.extra_3_single_value">
        <vs-row>
          <vs-input v-model="extra_3_multi_value_add" class="w-80" :label-placeholder="$t('Auswahl hinzufügen')"/>
          <div  class="pt-3 ms-3">
            <vs-button color="primary" type="filled" icon="add" @click="addExtraFieldOption(3)"></vs-button>
          </div>
        </vs-row>
        <vs-col vs-w="12" class="mt-2">
          <vs-chip @click="removeExtraFieldOption(3, v)" v-for="v, idx in layout.extra_3_values" :key="idx" closable>
            {{ v }}
          </vs-chip>
        </vs-col> 
       </vs-col> 
    </vs-row>



    <!--  EXTRA FIELD 4 -->
    <vs-row vs-w="12" class="mt-4" v-if="hasExtraField(4)">
    <vs-divider />
      <vs-col vs-w="6" >
        <vs-input v-model="layout.extra_4_caption" class="w-75" :label-placeholder="$t('Bezeichnung für Zusatz 4')" :disabled="isFieldDisabled('extra_4')" @change="updateFields('back', 'extra_4_caption')"/>
        <vs-row  class="mt-2">
          <span>{{ $t("Mehrfachauswahl") }}</span>
          <vs-switch class="ms-2" v-model="layout.extra_4_single_value" />
        </vs-row>
      </vs-col>
      
      <vs-col vs-w="6" v-if="layout.extra_4_single_value">
        <vs-row>
          <vs-input v-model="extra_4_multi_value_add" class="w-80" :label-placeholder="$t('Auswahl hinzufügen')"/>
          <div  class="pt-3 ms-3">
            <vs-button color="primary" type="filled" icon="add" @click="addExtraFieldOption(4)"></vs-button>
          </div>
        </vs-row>
        <vs-col vs-w="12" class="mt-2">
          <vs-chip @click="removeExtraFieldOption(4, v)" v-for="v, idx in layout.extra_4_values" :key="idx" closable>
            {{ v }}
          </vs-chip>
        </vs-col> 
       </vs-col> 
    </vs-row>


     <!--  EXTRA FIELD 5 -->
     <vs-row vs-w="12" class="mt-4" v-if="hasExtraField(5)">
    <vs-divider />
      <vs-col vs-w="6" >
        <vs-input v-model="layout.extra_5_caption" class="w-75" :label-placeholder="$t('Bezeichnung für Zusatz 5')" :disabled="isFieldDisabled('extra_5')" @change="updateFields('back', 'extra_5_caption')"/>
        <vs-row  class="mt-2">
          <span>{{ $t("Mehrfachauswahl") }}</span>
          <vs-switch class="ms-2" v-model="layout.extra_5_single_value" />
        </vs-row>
      </vs-col>
      
      <vs-col vs-w="6" v-if="layout.extra_5_single_value">
        <vs-row>
          <vs-input v-model="extra_5_multi_value_add" class="w-80" :label-placeholder="$t('Auswahl hinzufügen')"/>
          <div  class="pt-3 ms-3">
            <vs-button color="primary" type="filled" icon="add" @click="addExtraFieldOption(5)"></vs-button>
          </div>
        </vs-row>
        <vs-col vs-w="12" class="mt-2">
          <vs-chip @click="removeExtraFieldOption(5, v)" v-for="v, idx in layout.extra_5_values" :key="idx" closable>
            {{ v }}
          </vs-chip>
        </vs-col> 
       </vs-col> 
    </vs-row>


     <!--  EXTRA FIELD 6 -->
     <vs-row vs-w="12" class="mt-4" v-if="hasExtraField(6)">
    <vs-divider />
      <vs-col vs-w="6" >
        <vs-input v-model="layout.extra_6_caption" class="w-75" :label-placeholder="$t('Bezeichnung für Zusatz 6')" :disabled="isFieldDisabled('extra_6')" @change="updateFields('back', 'extra_6_caption')"/>
        <vs-row  class="mt-2">
          <span>{{ $t("Mehrfachauswahl") }}</span>
          <vs-switch class="ms-2" v-model="layout.extra_6_single_value" />
        </vs-row>
      </vs-col>
      
      <vs-col vs-w="6" v-if="layout.extra_6_single_value">
        <vs-row>
          <vs-input v-model="extra_6_multi_value_add" class="w-80" :label-placeholder="$t('Auswahl hinzufügen')"/>
          <div  class="pt-3 ms-3">
            <vs-button color="primary" type="filled" icon="add" @click="addExtraFieldOption(6)"></vs-button>
          </div>
        </vs-row>
        <vs-col vs-w="12" class="mt-2">
          <vs-chip @click="removeExtraFieldOption(6, v)" v-for="v, idx in layout.extra_6_values" :key="idx" closable>
            {{ v }}
          </vs-chip>
        </vs-col> 
       </vs-col> 
    </vs-row>


    <!-- SPACER -->
    <vs-row class="mb-4 mt-4"></vs-row>

  </div>
</template>

<script>

import Vue from "vue";


export default Vue.extend({
  props: ["layout"],

  mounted() {
    this.initFields()
  },

  data() {
    return {
      extra_1_multi_value_add: "",
      extra_2_multi_value_add: "",
      extra_3_multi_value_add: "",
      extra_4_multi_value_add: "",
      extra_5_multi_value_add: "",
      extra_6_multi_value_add: "",

      fields_front: {
        id_number: true,
        organization: true,
        name: true,
        date_of_birth: true,
        valid_until: true,
        function: true,
        issuing_authority_name: true,
      },
      fields_back: {
        department: false,
        driver_license_class: false,
        extra_1: false,
        extra_2: false,
        extra_3: false,
        extra_4: false,
        extra_5: false,
        extra_6: false,
      },  
    };
  },
  computed: {
    selectedFieldsCount() {
      // Zähle die ausgewählten Felder
      return Object.values(this.fields_back).filter(field => field).length;
    },
  },


  watch: {
    'fields_back.extra_1': function(newValue) {
    if (!newValue) {
      // Switch wurde deaktiviert, Input-Feld leeren
      this.layout.extra_1_caption = '';
    }
  },
  'fields_back.extra_2': function(newValue) {
    if (!newValue) {
      // Switch wurde deaktiviert, Input-Feld leeren
      this.layout.extra_2_caption = '';
    }
  },
  'fields_back.extra_3': function(newValue) {
    if (!newValue) {
      // Switch wurde deaktiviert, Input-Feld leeren
      this.layout.extra_3_caption = '';
    }
  },
  'fields_back.extra_4': function(newValue) {
    if (!newValue) {
      // Switch wurde deaktiviert, Input-Feld leeren
      this.layout.extra_4_caption = '';
    }
  },
  'fields_back.extra_5': function(newValue) {
    if (!newValue) {
      // Switch wurde deaktiviert, Input-Feld leeren
      this.layout.extra_5_caption = '';
    }
  },
  'fields_back.extra_6': function(newValue) {
    if (!newValue) {
      // Switch wurde deaktiviert, Input-Feld leeren
      this.layout.extra_6_caption = '';
    }
  },
    layout: {
      deep: true,
      handler(val) {
        this.setSwitches()
      },
    },
  },

  methods: {
    initFields() {
      this.fields_back = {
        department: false,
        driver_license_class: false,
        extra1: false,
        extra2: false,
        extra3: false,
        extra4: false,
        extra5: false,
        extra6: false,
      }
    },
    updateFields(side, field) {
      // Implementiere deine Logik zum Aktualisieren der Felder
      this.$emit('updateFields', side, field, this.fields_back[field]);
    },
    isFieldDisabled(fieldName) {
      // Überprüfe, ob das Feld deaktiviert sein sollte
      return this.selectedFieldsCount >= 6 && !this.fields_back[fieldName];
    },
  
    

    hasExtraField(no) {
      return this.layout.stylesheet.fields_back.find(f => f.key == 'extra_'+no)
    },

    setSwitches() {
      //const front = this.layout.stylesheet.fields_front
      const back = this.layout.stylesheet.fields_back

      this.initFields()

      for (const i in back) {
        const val = back[i].key
        this.fields_back[val] = true
      }

    },

    updateFields(side) {
      if (side === "back") {
        this.layout.stylesheet.fields_back = []
        for (const field in this.fields_back) {
          if (this.fields_back[field] && (!(field === "undefined"))) {
            this.layout.stylesheet.fields_back.push({
              "key": field
            })           
          } else {
            this.fields_back[field] = false
          }
        }
      }
    },  

    addExtraFieldOption(field) {
      if (this.layout.extra_1_values == null) {
        this.layout.extra_1_values = []
      }
      if (this.layout.extra_2_values == null) {
        this.layout.extra_2_values = []
      }
      if (this.layout.extra_3_values == null) {
        this.layout.extra_3_values = []
      }
      if (this.layout.extra_4_values == null) {
        this.layout.extra_4_values = []
      }
      if (this.layout.extra_5_values == null) {
        this.layout.extra_5_values = []
      }
      if (this.layout.extra_6_values == null) {
        this.layout.extra_6_values = []
      }   



      if (field===1) {
        if (this.extra_1_multi_value_add.trim() == '') {
          return
        }
        this.layout.extra_1_values.push(this.extra_1_multi_value_add)
        this.extra_1_multi_value_add = ""
      }
      if (field===2) {
        if (this.extra_2_multi_value_add.trim() == '') {
          return
        }
        this.layout.extra_2_values.push(this.extra_2_multi_value_add)
        this.extra_2_multi_value_add = ""
      }
      if (field===3) {
        if (this.extra_3_multi_value_add.trim() == '') {
          return
        }
        this.layout.extra_3_values.push(this.extra_3_multi_value_add)
        this.extra_3_multi_value_add = ""
      }
      if (field===4) {
        if (this.extra_4_multi_value_add.trim() == '') {
          return
        }
        this.layout.extra_4_values.push(this.extra_4_multi_value_add)  
        this.extra_4_multi_value_add = ""
      }
      if (field===5) {
        if (this.extra_5_multi_value_add.trim() == '') {
          return
        }
        this.layout.extra_5_values.push(this.extra_5_multi_value_add)  
        this.extra_5_multi_value_add = ""
      }
      if (field===6) {
        if (this.extra_6_multi_value_add.trim() == '') {
          return
        }
        this.layout.extra_6_values.push(this.extra_6_multi_value_add)  
        this.extra_6_multi_value_add = ""
      }
    },

    removeExtraFieldOption(field, value) {
      if (field===1) {
        this.layout.extra_1_values = _.without(this.layout.extra_1_values, value)
      }
      if (field===2) {
        this.layout.extra_2_values = _.without(this.layout.extra_2_values, value)
      }
      if (field===3) {
        this.layout.extra_3_values = _.without(this.layout.extra_3_values, value)
      }
      if (field===4) {
        this.layout.extra_4_values = _.without(this.layout.extra_4_values, value)      
      }
      if (field===5) {
        this.layout.extra_5_values = _.without(this.layout.extra_5_values, value)      
      }
      if (field===6) {
        this.layout.extra_6_values = _.without(this.layout.extra_6_values, value)      
      }
    },







  },
});
</script>


<style scoped>
.grayed-out {
  opacity: 0.5; /* Ändere den Wert nach Bedarf für den gewünschten Grau-Effekt */
}
/* .content-section {
  height: 55vh;
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
.content-section .child {
  flex: 1 1 40%;
  margin: 5px 10px;
}
.content-section .child .switch-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 5px;
} */

</style>